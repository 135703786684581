<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>商品小分类</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">小分类名称：</div>
                <el-input v-model="search.smallAdvertise" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="smallCategoriesInfoAdd" @click="goAdd">新增商品小分类</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="smallAdvertise" label="小分类名称"></el-table-column>
            <el-table-column align="center" label="小分类图标">
              <template slot-scope="scope"><img @click="handleSee(scope.row.smallIcon)" class="icon-img"
                                                :src="scope.row.smallIcon"/></template>
            </el-table-column>
            <el-table-column align="center" prop="categoriesName" label="所属大分类"></el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="smallCategoriesInfoUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="smallCategoriesInfoDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm">
        <el-form-item label="所属大分类：" prop="categoriesId">
          <span v-if="type === 'details'"> {{ upData.categoriesName }}</span>
          <el-select v-else v-model="upData.categoriesId" placeholder="请选择所属大分类">
            <el-option :label="item.categoriesName" :value="item.id" v-for="(item,index) in categoriesList"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小分类名称：" prop="smallAdvertise">
          <span v-if="type === 'details'"> {{ upData.smallAdvertise }}</span>
          <el-input v-else v-model="upData.smallAdvertise" placeholder="请输入小分类名称"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <span v-if="type === 'details'"> {{ upData.sort }}</span>
          <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1" v-model="upData.sort" placeholder="请输入排序"></el-input-number>
        </el-form-item>
        <el-form-item label="小分类图标：" prop="smallIcon">
          <div class="flexRow">
            <div class="up-image" v-if="type === 'details'">
              <div class="image-list">
                <img @click="handleSee(upData.smallIcon)" :src="upData.smallIcon" alt/>
              </div>
            </div>
            <div class="up-image" v-else>
              <div v-for="(item, index) in upList" class="image-list" :key="index">
                <img @click="handleSee(item.url)" :src="item.url" alt/>
                <i class="el-icon-error" @click="removeImage(index)"></i>
              </div>
            </div>

            <el-upload
                v-if="type !== 'details'"
                action
                accept=".jpg, .jpeg, .png, .gif"
                :on-exceed="onExceed"
                :on-error="onError"
                :file-list="upList"
                list-type="picture-card"
                :show-file-list="false"
                :auto-upload="true"
                :http-request="fnUploadRequest"
                :before-upload="beforeFileUpload"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>

      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateSort = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入排序'));
      }else {
        callback();
      }
    };
    return {
      search: {
        smallAdvertise: "",
      },
      type: "",
      tableList: [],
      categoriesList: [],
      upList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      formDataReady: true,
      tableLoading: false,
      dialogImgUrl: "",
      upData: {
        categoriesId: "",
        smallAdvertise: "",
        smallIcon: "",
        sort: 0
      },
      rules: {
        smallAdvertise: [{required: true, message: "请输入分类名称", trigger: "blur"}],
        smallIcon: [{required: true, message: "请上传小分类图标", trigger: "blur"}],
        sort: [{required: true, validator: validateSort, trigger: "blur"}],
        categoriesId: [{required: true, message: "请选择所属大分类", trigger: "change"}]
      },
      smallCategoriesInfoAdd: false,
      smallCategoriesInfoDel: false,
      smallCategoriesInfoUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  watch: {
    type: function (val) {
      // console.log(val)
    },
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }
      _this.$api
          .getSmallCategoriesInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "smallCategoriesInfo:add") {
          _this.smallCategoriesInfoAdd = true;
        }
        if (v == "smallCategoriesInfo:del") {
          _this.smallCategoriesInfoDel = true;
        }
        if (v == "smallCategoriesInfo:upd") {
          _this.smallCategoriesInfoUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 获取商品大分类
    getCategoriesData() {
      let _this = this;
      if(_this.categoriesList.length > 0) {return;}
      const requestData = {
        page: this.page,
        pageSize: this.pageSize
      }
      _this.$api.getAllCategoriesInfo(requestData).then(res => {
        if (res.data.code == 200) {
          _this.categoriesList = res.data.data;
        }
      });
    },
    add() {
      let _this = this;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addSmallCategoriesInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.handleClose();
                _this.getData();
              }
            });
          } else {
            _this.$api.editSmallCategoriesInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("修改成功");
                _this.getData();
                _this.handleClose();
              }
            });
          }
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        categoriesId: "",
        smallAdvertise: "",
        smallIcon: "",
        sort: 0
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search.smallAdvertise = null;
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },

    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.getCategoriesData();
      _this.type = 'add';
      _this.upData = {
        categoriesId: "",
        smallAdvertise: "",
        smallIcon: "",
        sort: 0
      };
      _this.upList = [];
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.getCategoriesData();
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.getCategoriesData(_this.upData.categoriesId);
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.smallIcon
      });
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.getCategoriesData();
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.smallIcon
      });
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delSmallCategoriesInfo({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
    // 上传start
    handleRemove() {
      let _this = this;
      // _this.advertiseImage = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      _this.upList.splice(e, 1);
      _this.upData.smallIcon = "";
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.upList = [];
        _this.upList.push(res);
        _this.upData.smallIcon = res.url;
      });
    }
    // 上传end
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}
.el-input-number {
  width: 100%;
  /deep/.el-input__inner {
    text-align: left;
  }
}
</style>
